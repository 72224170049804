<template>
  <div class="worksManage">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">作品管理</div>
      <div class="rightBtnBox">
        <div @click="edit" class="editIcon" v-if="activeName == 'notReviewed'">
          {{ !isEdit ? '编辑' : '取消' }}
        </div>
        <router-link tag="div" to="/help">
          <svg-icon class="rightBtn" iconClass="help"></svg-icon>
        </router-link>
      </div>
    </div>
    <!-- <div @click="edit">
      <div class="editIcon" v-if="activeName == 'notReviewed'">
        {{ !isEdit ? '编辑' : '取消' }}
      </div>
    </div> -->
    <van-tabs
      v-model="activeName"
      background="#fff"
      title-active-color="rgb(255,103,143)"
      title-inactive-color="rgb(51,51,51)"
      class="vant_tabs"
      animated
      :swipeable="true"
      @change="changeTab"
    >
      <van-tab title="已发布" name="published">
        <WorkList status="1" />
      </van-tab>
      <van-tab title="审核中" name="underReview">
        <WorkList status="0" />
      </van-tab>
      <van-tab title="未过审" name="notReviewed">
        <WorkList status="2" ref="notReviewed" @showReason="showReason" @closeEdit="edit" />
      </van-tab>
    </van-tabs>

    <van-popup v-model="isReason" class="reasonBox" @closed="close">
      <div class="popupsBox">
        <div class="reasonTitle">审核通知</div>
        <div class="reasonContent">{{ reasonText }}</div>
      </div>
      <div class="closeBtn" @click="isReason = false">
        <svg-icon class="closeIcon" icon-class="y_close" />
      </div>
    </van-popup>
    <div class="delBtnBox" :class="{ activeDelBtnBox: isEdit }">
      <div class="selectAll" @click="selectAll">
        <svg-icon class="select" iconClass="batchSelected" v-if="isSelectAll" />
        <svg-icon class="select" iconClass="batchUnSelect" v-else />
        <span>全选</span>
      </div>
      <div class="verticalLine1"></div>
      <div class="del" @click="delBtn">
        <span>删除</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Tab, Tabs } from 'vant';
import WorkList from './WorkList';
import { Popover, Popup } from 'vant';
export default {
  name: 'worksManages',
  components: {
    WorkList,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Popup.name]: Popup,
    [Popover.name]: Popover,
  },
  data() {
    return {
      activeName: 'published',
      isReason: false,
      reasonText: '',
      isSelectAll: false, // 是否全选
      isEdit: false, // 是否编辑
    };
  },
  created() {
    this.activeName = this.$route.query.t || 'published';
  },
  methods: {
    edit() {
      if (this.$refs.notReviewed.list.length == 0) return;
      this.isEdit = !this.isEdit;
      this.$refs.notReviewed.edit(this.isEdit);
    },
    changeTab(type) {
      this.isEdit = false;
      if (this.$refs.notReviewed) {
        this.$refs.notReviewed.edit(false);
      }
      this.$router.replace({ path: '/worksManage', query: { t: type } });
    },
    showReason(text) {
      this.reasonText = text;
      this.isReason = true;
    },
    close() {
      this.reasonText = '';
    },
    // 选择全部
    selectAll() {
      this.isSelectAll = !this.isSelectAll;
      this.$refs.notReviewed.selectAll(this.isSelectAll);
    },
    // 删除
    delBtn() {
      this.$refs.notReviewed.batchDel();
    },
  },
};
</script>

<style lang="scss" scoped>
.worksManage {
  height: 100%;
  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid #e0e0e0;
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtnBox {
      width: 80px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .editIcon {
        margin-right: 5px;
      }
      .rightBtn {
        height: 22px;
        width: 22px;
      }
    }
  }
  // .editIcon {
  //   // width: 24px;
  //   height: 24px;
  //   position: absolute;
  //   right: 14px;
  //   top: 54px;
  //   z-index: 999;
  // }
  .vant_tabs {
    height: 100%;

    /deep/ .van-tabs__wrap {
      height: 40px;
      padding: 0 30px;
      background-color: #fff;
    }

    /deep/ .van-tab__text {
      font-size: 16px;
    }

    /deep/ .van-tabs__nav {
      // height: 28px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }

    /deep/ .van-tab {
      width: 56px;
    }

    /deep/ .van-tabs__line {
      width: 20px;
      height: 4px;
      background: rgb(255, 103, 143);
    }

    /deep/ .van-tab__pane {
      height: 100%;
    }

    /deep/ .van-tab__pane-wrapper {
      height: 100%;
    }

    /deep/ .van-tabs__track {
      height: 100%;
    }

    /deep/ .van-tabs__content {
      height: 100%;
    }
  }

  .reasonBox {
    background: #00000000;
    width: 100%;
    .popupsBox {
      background-color: rgba(255, 255, 255, 0.96);
      border-radius: 5px;
      text-align: center;
      padding: 15px 0;
      margin: 0 38px;
      min-height: 150px;
      .reasonTitle {
        text-align: center;
        font-size: 20px;
        color: rgb(132, 132, 132);
        margin-bottom: 16px;
      }
      .reasonContent {
        font-size: 16px;
        text-align: left;
        padding: 0 10px;
        color: rgb(100, 100, 100);
      }
    }
    .closeBtn {
      width: 33.2px;
      height: 33.2px;
      margin: 20px auto;
      .closeIcon {
        width: 100%;
        height: 100%;
      }
    }
  }
  .delBtnBox {
    height: 60px;
    width: 100%;
    position: fixed;
    left: 0px;
    bottom: 0px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    transform: translateY(60px);
    transition: transform 0.5s;
    .selectAll {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        margin-left: 5px;
      }
    }
    .verticalLine1 {
      height: 35px;
      width: 1px;
      background-color: rgb(205, 212, 223);
    }
    .del {
      flex: 1;
      color: rgb(255, 103, 143);
      text-align: center;
    }
  }
  .activeDelBtnBox {
    transform: translateY(0px);
    transition: transform 0.3s;
  }
}
</style>
