<template>
  <div class="workList">
    <PullRefresh
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      @onRefresh="onRefresh"
      :isHigehtMax="true"
      :isNoData="isNoData"
      :error="error"
    >
      <div class="workItem" v-for="(item, index) in list" :key="item.id" @click="openVideos(item, index)">
        <div class="selectBox" @click.stop="selectItem(item)" :class="{ activeSelectBox: isEdit }">
          <svg-icon class="select" iconClass="batchSelected" v-if="item.select" />
          <svg-icon class="select" iconClass="batchUnSelect" v-else />
        </div>
        <div class="main" :class="{ activeMain: isEdit }">
          <div class="mainBox">
            <div class="workImg">
              <!-- <div class="mask" v-if="isEdit">
              <div @click.stop="del(item)">
                <svg-icon class="workDelete" iconClass="work_delete"></svg-icon>
              </div>
            </div> -->
              <ImgDecypt class="avatar" :src="item.cover" />
            </div>
            <div class="contentBox">
              <div class="workDesc">
                {{ item.title }}
              </div>
              <div class="dataBox">
                <div class="box">
                  播放:
                  <p class="white">{{ item.playCount | watchCount }}</p>
                </div>
                <div class="verticalLine"></div>
                <div class="box">
                  销量:
                  <p class="white">{{ item.purchaseCount | watchCount }}</p>
                </div>
                <div class="verticalLine"></div>
                <div class="box" v-if="status == 0">审核中</div>
                <div class="box pink" @click.stop="toPromote(item)" v-if="status == 1">推广</div>
                <div class="box" @click.stop="showReason(item)" v-if="status == 2">查看原因</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PullRefresh>
  </div>
</template>

<script>
import { Toast } from 'vant';
import { queryCollection, removeVid } from '@/api/video';
import ImgDecypt from '@/components/ImgDecypt';
import PullRefresh from '@/components/PullRefresh';
import { mapGetters } from 'vuex';
// import { Popover, Popup } from "vant";
export default {
  name: 'workList',
  components: {
    ImgDecypt,
    PullRefresh,
    // [Popup.name]: Popup,
    // [Popover.name]: Popover,
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo',
    }),
  },
  props: {
    status: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isEdit: false,
      list: [],
      pageNumber: 1, // 页码
      pageSize: 10, // 条数
      loading: true, // loading
      refreshing: false, // 刷新状态
      finished: false, // 下拉状态
      error: false, // 是否显示错误信息
      isNoData: false, // 暂无数据
    };
  },
  created() {
    this.getList();
  },
  methods: {
    openVideos(item, index) {
      if (item?.newsType === 'SP') {
        this.$router.push({
          path: '/communityVideo',
          query: {
            via: 6,
            vid: item.id,
          },
        });
        return;
      }
      if (item?.newsType == 'COVER') {
        this.$router.push({ path: '/postDetails', query: { id: item.id } });
        return;
      }
      if (item?.newsType == 'MOVIE') {
        this.$router.push({ path: '/horizontalVideo', query: { id: item.id } });
        return;
      }
    },
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        status: this.status, // ""-全部 0-未审核 1-通过 2-审核失败 3-视为免费 默认为0
      };
      try {
        let res = await this.$Api(queryCollection, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === 'refresh') {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },
    // 选择批量删除的数据
    selectItem(work) {
      this.list.map((item) => (item.id == work.id ? this.$set(item, 'select', !item.select) : ''));
    },
    // 全选
    selectAll(isSelectAll) {
      this.list.map((item) => this.$set(item, 'select', isSelectAll));
    },
    // 批量删除
    batchDel() {
      const delArr = this.list.map((item) => (item.select ? item.id : undefined)).filter((item) => item);
      if (delArr.length == 0) return Toast('至少选择一项进行删除');
      this.del(delArr);
    },
    // 上拉加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList('refresh');
    },
    // 编辑
    edit(isEdit) {
      this.isEdit = isEdit;
    },
    // 删除
    async del(items) {
      let req = {
        ids: items,
      };
      this.$store.commit('app/SET_LOADING', true);
      try {
        let res = await this.$Api(removeVid, req);
        this.$store.commit('app/SET_LOADING', false);
        if (res.code === 200) {
          // this.list = this.list.filter((i) => {
          //   return i.id != item.id;
          // });
          // if (this.list.length <= 0) {
          //   this.isNoData = true;
          // }
          this.pageNumber = 1;
          this.loading = true;
          this.getList('refresh');
          // this.$emit('closeEdit'); // 关闭编辑
          Toast('删除成功');
          return;
        }
        Toast('操作失败');
      } catch (e) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('操作失败');
      }
    },
    // 显示拒绝理由谈弹窗
    showReason(item) {
      this.isReason = true;
      this.$emit('showReason', item.reason);
    },
    // 去推广
    toPromote(item) {
      if (item.newsType != 'SP') return Toast('图文和影视暂不支持推广');
      if (!this.userInfo.isVip) return this.$bus.$emit('promptPopupEvent', '您还不是VIP无法推广');
      let data = {
        id: item.id,
        action: item.publisherTop ? 1 : 2,
        popShow: true,
        type: item.newsType,
        promoteStatus: item.isPopping ? 1 : 2,
        status: item.status || 0,
        publisherPopStatus: item.publisherPopStatus || false,
        promoteInfo: {
          cover: item.cover,
          title: item.title,
          user: item.publisher,
        },
      };
      this.$store.commit('user/UPDATE_SETTOPDATA', data);
      this.$router.push('/promotiionSettings');
    },
  },
};
</script>

<style lang="scss" scoped>
.workList {
  height: calc(100% - 90px);
  padding-top: 10px;
  width: 100%;
  .workItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 100%;
    .selectBox {
      padding: 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateX(-42px);
      transition: transform 0.3s;
      .select {
        height: 22px;
        width: 22px;
        border-radius: 50%;
      }
    }
    .main {
      background-color: #fff;
      width: 100%;
      padding: 8px 0px;
      transform: translateX(-42px);
      transition: transform 0.3s;
      .mainBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 350px;
        margin: 0 auto;
        padding: 0 12px;
        .workImg {
          width: 87px;
          height: 87px;
          border-radius: 4px;
          overflow: hidden;
          position: relative;

          .mask {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(0, 0, 0, 0.6);
            z-index: 9;

            .workDelete {
              width: 42.5px;
              height: 42.5px;
            }
          }
        }

        .contentBox {
          height: 85px;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 7px;
          padding-top: 5px;
          box-sizing: border-box;
          .workDesc {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            font-size: 14px;
            white-space: pre-wrap;
            color: rgb(34, 34, 34);
          }

          .dataBox {
            display: flex;
            align-items: center;
            background-color: rgb(246, 246, 246);
            height: 33px;
            font-size: 14px;
            border-radius: 2px;
            .box {
              flex: 1;
              display: flex;
              justify-content: center;
              padding: 8px 0;
              color: rgb(102, 102, 102);

              .white {
                color: rgb(255, 103, 143);
              }
            }
            .verticalLine {
              height: 16px;
              width: 1px;
              background-color: rgb(230, 230, 230);
            }
            span {
              margin-right: 32px;
            }

            .pink {
              color: rgb(255, 103, 143);
            }
          }
        }
      }
    }
    .activeSelectBox {
      transform: translateX(0px);
      transition: transform 0.3s;
    }
    .activeMain {
      transform: translateX(0px);
      transition: transform 0.3s;
    }
  }
}
</style>
